import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { InputText } from 'primereact/inputtext';

// funcoes proprias
// import BarraInferior from '../componentes/BarraInferior';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Receber() {
    const { logout } = useContext(AuthContext);
    const ultimo_dia = moment(moment().format('YYYY-MM'), "YYYY-MM").daysInMonth();
    const scrollObserver = useRef();
    // const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ totais, setTotais ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ enviando, setEnviando ] = useState('');
    //const [ receber, setReceber ] = useState({});
    const [ dataInicial, setDataInicial ] = useState(moment().format('01/MM/YYYY'));
    const [ dataFinal, setDataFinal ] = useState(moment().format(ultimo_dia+'/MM/YYYY'));
    const [ status, setStatus ] = useState('TODOS');
    const [ filtros, setFiltros ] = useState('visually-hidden');
    const [ espaco, setEspaco ] = useState('topspace-50');

    function listarReceber(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', pesquisa);
            formData.set('datainicial', dataInicial);
            formData.set('datafinal', dataFinal);
            formData.set('status', status);
                       
            Api.post('receber.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg.listagem); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    if (novaPagina===1) {
                        setTotais(response.data.msg.totais);
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 40) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarReceber(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    useEffect(() => {
        listarReceber(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarReceber(0,false,true);
        showFiltros();
    };
    
    // async function btnSalvar() {
    //     const formData = new FormData();
    //     formData.set('op','inserir');
    //     formData.set('CLI_BAIRRO',receber.CLI_BAIRRO || '');
    //     formData.set('CLI_CEP',receber.CLI_CEP || '');
    //     formData.set('CLI_CIDADE',receber.CLI_CIDADE || '');
    //     const CLI_USUARIO = usuarioLogin;
    //     const CLI_DTATUALIZACAO = moment().format('DD/MM/YYYY');
    //     setReceber({...receber, CLI_DTATUALIZACAO, CLI_USUARIO});
    //     const response = await Api.post('clientes.php',formData);
    //     if (response.data.erro==='N') {
    //         Swal.fire({title: 'Yessss!', text: 'Cliente cadastrado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
    //         setShowModal(false);
    //         navigate('/cliente/'+response.data.msg.CLI_CODIGO);
    //     } 
    //     if (response.data.erro==='S') {
    //         Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
    //     }
    // }    

    // const setCampo = (e) => {
    //     const { name, value } = e.target;
    //     let valor = value?value.toUpperCase():'';
    //     if (name==='CLI_EMAIL') {
    //         valor = valor.toLowerCase();
    //     }
    //     setReceber({...receber, [name]: valor});
    // }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setStatus(value);
    }

    function showFiltros() {
        const filtro2=filtros===""?"visually-hidden":"";
        const espaco2=espaco==="topspace-150"?"topspace-50":"topspace-150";
        setFiltros(filtro2);    
        setEspaco(espaco2);    
    }
     
    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    async function enviarListagem() {
        setEnviando(true);
        const formData = new FormData();
        formData.set('op','enviarpdf');
        formData.set('pesquisa', pesquisa);
        formData.set('datainicial', dataInicial);
        formData.set('datafinal', dataFinal);
        formData.set('status', status);
        formData.set('email', email);
        try {
            const response = await Api.post('receber.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            }
            if (response.data.erro==='S') {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            console.log('CATCH',error);
        }
        setEmail('');
        setEnviando(false);
        setShowModal(false);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CONTAS A RECEBER</p></div>
                        <div className="link-icone me-3"><Link to='' onClick={() => setShowModal(true)}><i className="fa fa-2x fa-fw fa-share"></i></Link></div>
                        <div className="link-icone"><Link to='' onClick={() => showFiltros()}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid limit-width "+filtros}>
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            {/* <Dropdown className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light p-inputtext-sm disable-form-control-focus" name="status" value={status || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)}/> */}
                            <select className="form-select border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" name="status" value={status} onChange={(e) => handleInputChange(e)}>
                                <option value='TODOS'>TODOS</option>
                                <option value='A'>ABERTOS</option>
                                <option value='B'>BAIXADOS</option>
                                <option value='C'>CANCELADOS</option>
                                <option value='P'>PRE BAIXADOS</option>
                            </select>
                        </div>
                        <div className="w-100 mx-2">
                            <NumberFormat className='form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus'
                                name='datainicial'
                                value={dataInicial}
                                displayType="input"
                                type="text"
                                allowNegative={true}
                                format="##/##/####"
                                mask="_"
                                onValueChange={(e) => setDataInicial(e.formattedValue)}
                            />
                        </div>
                        <div className="w-100">
                            {/* <InputMask className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light p-inputtext-sm disable-form-control-focus" name="dataFinal" mask="99/99/9999"  value={dataFinal || ''} onChange={(e) => setCampo(e)}></InputMask> */}
                            {/* <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="dataFinal" value={dataFinal} onChange={(e) => setDataFinal(e.target.value())}/> */}
                            <NumberFormat className='form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus'
                                name='dataFinal'
                                value={dataFinal}
                                displayType="input"
                                type="text"
                                allowNegative={true}
                                format="##/##/####"
                                mask="_"
                                onValueChange={(e) => setDataFinal(e.formattedValue)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid limit-width "+filtros}>
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            {/* <InputText className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" name="texto" value={pesquisa || ''} onChange={(e) => setCampo(e)} /> */}
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())} onKeyDown={(e) => localizarKeyDown(e)}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="fixed-bottom bg-dark p-1">
            <div className="container-fluid limit-width" style={{fontSize: '11px'}}>
                <div className="row g-1">
                    <div className="col-2 text-light text-end">ABERTO&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-danger">
                        <NumberFormat 
                            value={parseFloat(totais.ABERTOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">PRÉ B.&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-primary">
                        <NumberFormat 
                            value={parseFloat(totais.PREBAIXADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>

                
                    <div className="col-2 text-light text-end">BAIXADO&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-success">
                        <NumberFormat 
                            value={parseFloat(totais.BAIXADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">TOTAL&nbsp;</div>
                    <div className="col-4 text-black text-end rounded bg-warning">
                        <NumberFormat 
                            value={parseFloat(totais.TOTAL)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>                  

                    <div className="col-2 text-light text-end">CANCEL.&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-secondary">
                        <NumberFormat 
                            value={parseFloat(totais.CANCELADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">PAGO&nbsp;</div>
                    <div className="col-4 text-black text-end rounded bg-light">
                        <NumberFormat 
                            value={parseFloat(totais.TOTALPAGO)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>

                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
        <div className={'container limit-width p-0 '+espaco} style={{marginBottom: '90px'}}>
            {                    
                listagem.map(receber => (
                    <div className="card rounded-0 border-0 border-bottom w-100" key={receber.REE_CODIGO}>
                        <div className="card-body text-truncate p-1">
                            {/* <div className="col-12 mx-2 lh-1 pt-2 text-truncate"> */}
                                    <Link to={"/receber/"+receber.REE_CODIGO} className="link-preto stretched-link">
                                        <span className="fw-bold">{receber.REE_CLIENTE} - {receber.CLI_NOME}</span>
                                    </Link>
                            {/* </div> */}
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className="small">{receber.REE_CODIGO}</span>
                                    <span className="text-muted small">{receber.REE_DTVENCIMENTO}</span>
                                    <span className="text-danger fw-bold small me-1">
                                        <NumberFormat 
                                            value={parseFloat(receber.REE_VALOR)}
                                            // isNumericString={true}
                                            displayType="text"
                                            prefix="R$ "
                                            thousandsGroupStyle="thousand"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator="."
                                            allowNegative={true}
                                        />&nbsp;
                                        <IfComponent condicional={receber.REE_STATUS==="A"}>
                                            <span className="text-light badge bg-danger">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="B"}>
                                            <span className="text-light badge bg-success">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="C"}>
                                            <span className="badge bg-secondary">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="P"}>
                                            <span className="text-light badge bg-primary">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }   
        </div>

        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-sack-dollar"></i></span><br/>Nenhuma conta a receber encontrada!</p>
            </div>                    
        </IfComponent>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">Enviar Contas a Receber</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="row mb-0 mt-5 gx-2 p-inputtext-sm pett-input">
                        <div className="col-12 mb-2">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EMAIL" value={email || ''} onChange={(e) => setEmail(e.target.value)} required/>
                                <label htmlFor="EMAIL">EMAIL DO DESTINATARIO</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        {
                            enviando
                                ? <Button variant="success disabled"><i className="fa fa-fw fa-spin fa-spinner"></i> Enviando...</Button>
                                : <Button variant="success" onClick={() => enviarListagem()}><i className="fa fa-fw fa-paper-plane"></i> Enviar</Button>
                        }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>
}

export default Receber;