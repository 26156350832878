import React from 'react';
import { Link } from 'react-router-dom';

function ErroPage() {
    return <>
        <div className='bg-dark'>
            <div className='container-fluid limit-width bg-dark'>
                <div className="row d-flex justify-content-center align-items-center text-center vh-100">
                    <div className='col-12'>
                        <img src='/images/logo_runtime_black.svg' style={{maxWidth: '280px'}} alt='Brian' />
                        <p className="mt-5 lead text-light">Xiiiiii... <br/>A página que você tentou acessar <br />não existe aqui.</p>
                        <Link to='/' className="btn btn-lg btn-success w-100 mt-4" type="button">Voltar a página inicial</Link>
                    </div>
                </div>
            </div>    
        </div>    
    </>
}

export default ErroPage;