import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
//import { MdCheckCircle, MdError, MdLink } from "react-icons/md";
//import { Container, FileInfo, Preview } from "./styles";
import './FileList.css';
import "react-circular-progressbar/dist/styles.css";

function FileList({ files, onDelete }) {
    return <>
        <div className="container-fluid limit-width mb-5">
            {files.map(uploadedFile => (    
                <div className="row filelist-container rounded rounded-3 mb-2" key={uploadedFile.id}>
                    <div className="col-12 m-0 p-2 d-flex justify-content-center align-items-center">
                        <div className="">
                            <div className='filelist-preview' style={{ backgroundImage: `url(${uploadedFile.preview})` }} />
                        </div>    
                        <div className="d-flex align-items-center w-100 ms-2">
                            <div className="row text-truncate">        
                                <div className="col-12">
                                    <a className="link-preto text-truncate" href={uploadedFile.url}><b>{uploadedFile.name}</b></a>
                                </div>        
                                <div className="col-12 text-truncate small text-black-50">
                                    {uploadedFile.readableSize}{" "}
                                    {!!uploadedFile.error && (<span className='text-danger'>{uploadedFile.errorMsg}</span>)}{" "}
                                    {!!uploadedFile.url && (
                                        <button onClick={() => onDelete(uploadedFile.id)}>Excluir</button>
                                    )}
                                </div> 
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{maxWidth: '36px'}}>
                            {!uploadedFile.uploaded && !uploadedFile.error && (
                                <CircularProgressbar
                                    value={uploadedFile.progress}
                                    text={`${uploadedFile.progress}%`}
                                    background
                                    backgroundPadding={6}
                                    styles={buildStyles({
                                        root: { width: 26 },
                                        backgroundColor: "#3e98c7",
                                        textColor: "#fff",
                                        pathColor: "gold",
                                        trailColor: "#fff"
                                    })}
                                />
                                // <CircularProgressbar
                                //     styles={{
                                //         root: { width: 26 },
                                //         path: { stroke: "#0d6efd" },
                                //         trailColor: '#d6d6d6',
                                //     }}
                                //     strokeWidth={12}
                                //     value={uploadedFile.progress}
                                //     text={uploadedFile.progress}
                                //     background={false}
                                // />
                            )}
                            {uploadedFile.url && ( <a href={uploadedFile.url} target="_blank" rel="noopener noreferrer"><i className='fa fa-fw fa-2x fa-link'></i></a> )}
                            {uploadedFile.uploaded && <i className='fa fa-fw fa-2x fa-check-circle text-success'></i>}
                            {uploadedFile.error && <i className='fa fa-fw fa-2x fa-times-circle text-danger'></i>}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>
}

export default FileList;