import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import NumberFormat from "react-number-format";
import moment from 'moment';

// funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import { ParametrosContext } from '../contextos/Parametros';
import IfComponent from '../componentes/IfComponent';
import Formata from '../componentes/Formata';

function ReceberId(props) {
    const { logout, usuarioLogin } = useContext(AuthContext);
    const { parametros } = useContext(ParametrosContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ showModal, setShowModal ] = useState(false);
    const [ receber, setReceber ] = useState({
        CLI_BAIRRO: '',
        CLI_CEP: '',
        CLI_CIDADE: '',
        CLI_CNPJ: '',
        CLI_CODIGO: '',
        CLI_COMPLEMENTO: '',
        CLI_CONTATO: '',
        CLI_CPF: '',
        CLI_DTATUALIZACAO: '',
        CLI_DTNASC: '',
        CLI_EMAIL: '',
        CLI_ENDERECO: '',
        CLI_ESTADO: '',
        CLI_FONE1: '',
        CLI_FONE2: '',
        CLI_FONE3: '',
        CLI_IE: '',   
        CLI_NOME: '',
        CLI_NUMERO: '',
        CLI_RG: '',
        CLI_STATUS: '',
        CLI_TABELAPRECO: '',
        CLI_TIPOPESSOA: '',
        CLI_USUARIO: ''
    });
    
    function VerReceber() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('receber', id);

        Api.post('receber.php',formData)
        .then((response) => {
            //setCliente(response.data.msg);
            if (response.data.erro==='N') {
                setReceber(response.data.msg);
            } else {
                //setCliente({CLI_CODIGO:0});
                const REE_CODIGO=0;
                setReceber({...receber, REE_CODIGO});
                if (response.data.token==='expirado') {
                    logout();
                }
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });
    }   
   
    useEffect(() => {
        VerReceber();
        // eslint-disable-next-line
    },[]);

    function btnEditar() {
        setShowModal(true);
    }

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        if (name==='CLI_EMAIL') {
            valor = valor.toLowerCase();
        }
        setReceber({...receber, [name]: valor});
    }

    // const handleInputChange2 = (v,e) => {
    //     setCliente({...cliente, [e.event.target.name]: v.floatValue});
    // }  
    const setCampoValor = (e) => {
        const { name, value } = e.target;
        setReceber({...receber, [name]: value});
    }  

    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','prebaixar');
        formData.set('receber',receber.REE_CODIGO || '');
        formData.set('valor',receber.REE_VALORPAGO || '');
        const REE_DTATUALIZACAO = moment().format('DD/MM/YYYY');
        const REE_BAIXADOPOR = usuarioLogin;
        const REE_USUARIO = usuarioLogin;
        const REE_STATUS = 'P';
        const response = await Api.post('receber.php',formData);
        if (response.data.erro==='N') {
            setShowModal(false); 
            setReceber({...receber, REE_DTATUALIZACAO, REE_BAIXADOPOR, REE_USUARIO, REE_STATUS});
            Swal.fire({position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
        } else {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'}); 
        }
    }

    return <>    
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">DETALHES RECEBER</p></div>
                        <div className="">
                            <div className="link-icone me-3">
                                <IfComponent condicional={receber.REE_STATUS==="A"}>
                                    <IfComponent condicional={parametros.PAR_REEPREBAIXA==="S"}>
                                        <Link to='' onClick={btnEditar}><i className="fa fa-2x fa-fw fa-pencil"></i></Link>
                                    </IfComponent>
                                </IfComponent>
                                <IfComponent condicional={receber.REE_STATUS!=="A"}>
                                    &nbsp;&nbsp; 
                                </IfComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={receber.REE_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar a conta a receber.</p>
            </div>  
        </IfComponent>
        <IfComponent condicional={receber.REE_CODIGO>0}>
            <div className="container-fluid limit-width mt-5 py-2">
                <ul className="list-group mb-3">  
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CÓDIGO</sup></p><p className="lh-1 m-0">{receber.REE_CODIGO}</p>
                            </div>    
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">STATUS</sup></p>
                                <IfComponent condicional={receber.REE_STATUS==='A'}>
                                    <p className="m-0 badge bg-danger">ABERTO</p>
                                </IfComponent>
                                <IfComponent condicional={receber.REE_STATUS==='B'}>
                                    <p className="m-0 badge bg-success">BAIXADO</p>
                                </IfComponent>
                                <IfComponent condicional={receber.REE_STATUS==='C'}>
                                    <p className="m-0 badge bg-secondary">CANCELADO</p>
                                </IfComponent>
                                <IfComponent condicional={receber.REE_STATUS==='P'}>
                                    <p className="m-0 badge bg-primary">PRE BAIXADO</p>
                                </IfComponent>
                            </div>      
                        </div>  
                    </li>                 
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">NOME / RAZÃO SOCIAL</sup></p><p className="lh-1 m-0">{receber.CLI_NOME}</p></div></li>  
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">RECEITA</sup></p><p className="lh-1 m-0">{receber.REC_DESCRICAO}</p></div></li>  
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">CONTA</sup></p><p className="lh-1 m-0">{receber.CON_NOME}</p></div></li>  
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DESCRIÇÃO</sup></p><p className="lh-1 m-0">{receber.REE_DESCRICAO}</p></div></li>  
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA CADASTRO</sup></p><p className="lh-1 m-0">{receber.REE_DTCADASTRO}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">BOLETO</sup></p><p className="lh-1 m-0">{receber.REE_BOLETO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA VENCIMENTO</sup></p><p className="lh-1 m-0">{receber.REE_DTVENCIMENTO}</p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">DATA PAGAMENTO</sup></p><p className="lh-1 m-0">{receber.REE_DTPAGAMENTO}</p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">PARCELA NÚMERO</sup></p><p className="lh-1 m-0"><Formata formato="0000">{receber.REE_PARCELANUM}</Formata></p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">TOTAL PARCELAS</sup></p><p className="lh-1 m-0"><Formata formato="0000">{receber.REE_PARCELAQTDE}</Formata></p>
                            </div>    
                        </div>  
                    </li> 
                    <li className="list-group-item p-2">    
                        <div className="row h34">      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">VALOR</sup></p><p className="lh-1 m-0">
                                    <NumberFormat 
                                        value={parseFloat(receber.REE_VALOR)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>      
                            <div className="col-6">
                                <p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">VALOR PAGO</sup></p><p className="lh-1 m-0">
                                    <NumberFormat 
                                        value={parseFloat(receber.REE_VALORPAGO)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </p>
                            </div>    
                        </div>  
                    </li>
                    <li className="list-group-item p-2"><div className="row h34"><p className="lh-1 m-0 mt-1"><sup className="text-muted sup-reset">BAIXADO POR</sup></p><p className="lh-1 m-0">{receber.REE_BAIXADOPOR}</p></div></li>
                </ul>
                <div className="row gx-2 mb-5">
                    
                </div>             
            </div>
        </IfComponent>
        {/* <BarraInferior active=""/> */}

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">PRE BAIXA</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="container limit-width">
                    <div className="row mb-0 mt-0 gx-1 p-inputtext-sm pett-input">
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="REE_CODIGO" value={receber.REE_CODIGO || ''} onChange={(e) => setCampo(e)} disabled readOnly/>
                                <label htmlFor="REE_CODIGO">CÓDIGO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100" name="REE_VALOR" value={receber.REE_VALOR || ''} mode="currency" currency="BRL" locale="pt-BR" disabled readOnly/>
                                <label htmlFor="REE_VALOR">VALOR</label>
                            </span>
                        </div>                    
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputNumber className="w-100" name="REE_VALORPAGO" value={receber.REE_VALORPAGO} onValueChange={(e) => setCampoValor(e)} mode="currency" currency="BRL" locale="pt-BR" />
                                <label htmlFor="REE_VALORPAGO">VALOR PAGO</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button className="w-100" variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        <Button className="w-100" variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

    </>
}

export default ReceberId;