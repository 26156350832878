import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';

function VendasConferencias() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ filtros, setFiltros ] = useState('visually-hidden');
    const [ espaco, setEspaco ] = useState('topspace-50');  

    function listarVendasConferencias(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listarconferencia'); //colocar no plural.
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);
                       
            Api.post('vendas.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarVendasConferencias(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    useEffect(() => {
        listarVendasConferencias(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarVendasConferencias(0,false,true);
        showFiltros();
    };
    
    function showFiltros() {
        const filtro2=filtros===""?"visually-hidden":"";
        const espaco2=espaco==="topspace-100"?"topspace-50":"topspace-100";
        setFiltros(filtro2);    
        setEspaco(espaco2);    
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CONFERÊNCIAS DE PEDIDOS</p></div>
                        <div className="link-icone"><Link to='' onClick={() => showFiltros()}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid limit-width "+filtros}>
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            {/* <InputText className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" name="texto" value={pesquisa || ''} onChange={(e) => setCampo(e)} /> */}
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Código do pedido ou nome cliente" onChange={(e) => setPesquisa(e.target.value.toUpperCase())} onKeyDown={(e) => localizarKeyDown(e)}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }}>Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
        <div className={'container limit-width p-0 '+espaco}>
            {                    
                listagem.map(venda => (
                    <div className="card rounded-0 border-0 border-bottom w-100" key={venda.VED_CODIGO}>
                        <div className="row">
                            <div className="col-12 px-3 pt-1 text-truncate">
                                <Link to={"/vendaconferencia/"+venda.VED_CODIGO} className="link-preto stretched-link">
                                    <span className="fw-bold">{venda.VED_CLIENTE} - {venda.VED_NOME}</span>
                                </Link>
                            </div>
                            <div className="row">                               
                                <div className="col-12 mx-2 pb-2 lh-1 d-flex justify-content-between align-items-center">
                                    <span className="small">{venda.VED_CODIGO}</span>
                                    <span className="text-muted small">{venda.VED_DTEMISSAO}</span>
                                    <span className="text-muted small">{venda.VED_CONFERIDOPOR}</span>
                                    <span className="text-muted small">{venda.VED_CONFERIDODATA}</span>
                                    <span className="text-danger fw-bold small">
                                        <IfComponent condicional={venda.VED_CONFERIDO==="N"}>
                                            <span className="text-light badge bg-primary">NAO CONFERIDO</span>
                                        </IfComponent>
                                        <IfComponent condicional={venda.VED_CONFERIDO==="S"}>
                                            <span className="text-light badge bg-success">CONFERIDO</span>
                                        </IfComponent>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }   
        </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-shopping-cart"></i></span><br/>Nenhum pedido de venda encontrado!</p>
            </div>                    
        </IfComponent>
        {/* <BarraInferior active="clientes"/> */}

    </>
}

export default VendasConferencias;