import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from "react-number-format";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Button as ButtonP } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

// funcoes proprias
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import ClientesLoc from '../componentes/ClientesLoc';
import VendedoresLoc from '../componentes/VendedoresLoc';

function Servicos() {
    const { logout } = useContext(AuthContext);
    const ultimo_dia = moment(moment().format('YYYY-MM'), "YYYY-MM").daysInMonth();
    const scrollObserver = useRef();
    const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ dataInicial, setDataInicial ] = useState(moment().format('01/MM/YYYY'));
    const [ dataFinal, setDataFinal ] = useState(moment().format(ultimo_dia+'/MM/YYYY'));
    const [ status, setStatus ] = useState('TODOS');
    const [ filtros, setFiltros ] = useState('visually-hidden');
    const [ espaco, setEspaco ] = useState('topspace-50');
    const [ servico, setServico ] = useState({'SER_CLIENTE': '', 'CLI_NOME':''});
    
    const [ showModal, setShowModal ] = useState(false); //para novo pedido.
    const [ showClientesLoc, setShowClientesLoc ] = useState(false);
    const [ showVendedoresLoc, setShowVendedoresLoc ] = useState(false);

    function listarServicos(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);
                bodyFormData.set('datainicial', dataInicial);
                bodyFormData.set('datafinal', dataFinal);
                bodyFormData.set('status', status);
                       
            Api.post('servicos.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarServicos(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    useEffect(() => {
        listarServicos(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarServicos(0,false,true);
        showFiltros();
    };
    
    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','inserirservico');
        formData.set('SER_ACESSORIOS',servico.SER_ACESSORIOS || '');
        formData.set('SER_CLIENTE',servico.SER_CLIENTE || '');
        formData.set('SER_DEFEITO',servico.SER_DEFEITO || '');
        formData.set('SER_DTENTRADA',servico.SER_DTENTRADA || '');
        formData.set('SER_DTPREVISAO',servico.SER_DTPREVISAO || '');
        formData.set('SER_MARCA',servico.SER_MARCA || '');
        formData.set('SER_MODELO',servico.SER_MODELO || '');
        formData.set('SER_PRODUTO',servico.SER_PRODUTO || '');
        formData.set('SER_SERIE',servico.SER_SERIE || '');
        formData.set('SER_TECNICO',servico.SER_TECNICO || '');
        const response = await Api.post('servicos.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: 'Ordem de serviço gerada com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setShowModal(false);
            navigate('/servico/'+response.data.msg.SER_CODIGO);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }    

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        setServico({...servico, [name]: valor});
    }

    const setCampoBlob = (e) => {
        const { name, value } = e.target;
        //let valor = value?value.toUpperCase():'';
        setServico({...servico, [name]: value});
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setStatus(value);
        //setDiso({...disco, [name]: value.toUpperCase() || ''});
    }

    function showFiltros() {
        const filtro2=filtros===""?"visually-hidden":"";
        const espaco2=espaco==="topspace-150"?"topspace-50":"topspace-150";
        setFiltros(filtro2);    
        setEspaco(espaco2);    
    }

    //funcoes do modal de clientes.
    function ClientesLocClose(event, data) {
        setServico({...servico, 'SER_CLIENTE': data.CLI_CODIGO, 'CLI_NOME': data.CLI_NOME })
        setShowClientesLoc(false);
    }

    function ClientesLocOpen(event) {
        setShowClientesLoc(true);
    }

    //funcoes do modal de condicoes.
    function VendedoresLocClose(event, data) {
        setServico({...servico, 'SER_TECNICO': data.VEN_CODIGO, 'VEN_NOME': data.VEN_NOME })
        setShowVendedoresLoc(false);
    }

    function VendedoresLocOpen(event) {
        setShowVendedoresLoc(true);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">ORDENS DE SERVIÇO</p></div>
                        <div className="link-icone me-3"><Link to='' onClick={() => setShowModal(true)}><i className="fa fa-2x fa-fw fa-plus"></i></Link></div>
                        <div className="link-icone"><Link to='' onClick={() => showFiltros()}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid limit-width "+filtros}>
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            {/* <Dropdown className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light p-inputtext-sm disable-form-control-focus" name="status" value={status || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)}/> */}
                            <select className="form-select border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" name="status" value={status} onChange={(e) => handleInputChange(e)}>
                                <option value='TODOS'>TODOS</option>
                                <option value='A'>ABERTOS</option>
                                <option value='B'>BAIXADOS</option>
                                <option value='F'>FINALIZADOS</option>
                            </select>
                        </div>
                        <div className="w-100 mx-2">
                            <NumberFormat className='form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus'
                                name='datainicial'
                                value={dataInicial}
                                displayType="input"
                                type="text"
                                allowNegative={true}
                                format="##/##/####"
                                mask="_"
                                onValueChange={(e) => setDataInicial(e.formattedValue)}
                            />
                        </div>
                        <div className="w-100">
                            {/* <InputMask className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light p-inputtext-sm disable-form-control-focus" name="dataFinal" mask="99/99/9999"  value={dataFinal || ''} onChange={(e) => setCampo(e)}></InputMask> */}
                            {/* <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="dataFinal" value={dataFinal} onChange={(e) => setDataFinal(e.target.value())}/> */}
                            <NumberFormat className='form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus'
                                name='dataFinal'
                                value={dataFinal}
                                displayType="input"
                                type="text"
                                allowNegative={true}
                                format="##/##/####"
                                mask="_"
                                onValueChange={(e) => setDataFinal(e.formattedValue)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container-fluid limit-width "+filtros}>
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            {/* <InputText className="w-100 border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" name="texto" value={pesquisa || ''} onChange={(e) => setCampo(e)} /> */}
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
        <div className={'container limit-width p-0 '+espaco}>
            {                    
                listagem.map(servico => (
                    <div className="card rounded-0 border-0 border-bottom w-100" key={servico.SER_CODIGO}>
                        <div className="row px-2">
                            <div className="col-12 text-truncate">
                                <Link to={"/servico/"+servico.SER_CODIGO} className="link-preto stretched-link">
                                    <span className="fw-bold">{servico.SER_CLIENTE} - {servico.CLI_NOME}</span>
                                </Link>
                            </div>
                            {/* <div className="row">                                */}
                                <div className="col-12 lh-2 text-truncate d-flex justify-content-between align-items-center">
                                    <span className="fw-bold small">{servico.VEN_NOME}</span>
                                    <span className="fw-bold small">{servico.SER_PRODUTO}</span>
                                </div>
                            {/* </div> */}
                            {/* <div className="row">                                */}
                                <div className="col-12 mb-1 lh-1 d-flex justify-content-between align-items-center">
                                    <span className="small">{servico.SER_CODIGO}</span>
                                    <span className="text-muted small">{servico.SER_DTENTRADA}</span>
                                    <span className="text-muted small">{servico.SER_DTPREVISAO}</span>
                                    <span className="text-danger fw-bold small">
                                        <NumberFormat 
                                            value={parseFloat(servico.SER_VALORTOTAL)}
                                            // isNumericString={true}
                                            displayType="text"
                                            prefix="R$ "
                                            thousandsGroupStyle="thousand"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator="."
                                            allowNegative={true}
                                        />&nbsp;
                                        <IfComponent condicional={servico.SER_STATUS==="A"}>
                                            <span className="text-light badge bg-primary">{servico.SER_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={servico.SER_STATUS==="B"}>
                                            <span className="badge bg-info">{servico.SER_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={servico.SER_STATUS==="F"}>
                                            <span className="text-light badge bg-success">{servico.SER_STATUS}</span>
                                        </IfComponent>
                                    </span>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                ))
            }   
        </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-screwdriver"></i></span><br/>Nenhuma O.S. encontrada!</p>
            </div>                    
        </IfComponent>
        {/* <BarraInferior active="clientes"/> */}

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">NOVA O.S.</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row gx-1 p-inputtext-sm pett-input">

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_CLIENTE" value={servico.SER_CLIENTE} readOnly/>
                                    <label htmlFor="SER_CLIENTE">CLIENTE</label>
                                </span>
                                <InputText className="w-75" name="CLI_NOME" value={servico.CLI_NOME} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => ClientesLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText name="SER_TECNICO" value={servico.SER_TECNICO || ''} readOnly/>
                                    <label htmlFor="SER_TECNICO">TÉCNICO</label>
                                </span>
                                <InputText className="w-75" name="VEN_NOME" value={servico.VEN_NOME || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => VendedoresLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_PRODUTO" value={servico.SER_PRODUTO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="SER_PRODUTO">PRODUTO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_MARCA" value={servico.SER_MARCA || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="SER_MARCA">MARCA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_MODELO" value={servico.SER_MODELO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="SER_MODELO">MODELO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_SERIE" value={servico.SER_SERIE || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="SER_SERIE">SÉRIE</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="SER_ACESSORIOS" value={servico.SER_ACESSORIOS || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="SER_ACESSORIOS">ACESSÓRIOS</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="SER_DTENTRADA" mask="99/99/9999" value={servico.SER_DTENTRADA || ''} onChange={(e) => setCampo(e)} ></InputMask>
                                <label htmlFor="SER_DTENTRADA">DATA ENTRADA</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="SER_DTPREVISAO" mask="99/99/9999" value={servico.SER_DTPREVISAO || ''} onChange={(e) => setCampo(e)} ></InputMask>
                                <label htmlFor="SER_DTPREVISAO">DATA PREVISÃO</label>
                            </span>
                        </div>
                        <div className="col-12">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="SER_DEFEITO" value={servico.SER_DEFEITO || ''} onChange={(e) => setCampoBlob(e)} autoResize />
                                <label htmlFor="SER_DEFEITO">DEFEITO ALEGADO</label>
                            </span>
                        </div>

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                    <Button variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>
        <ClientesLoc showModal={showClientesLoc} onCloseModal={ClientesLocClose}/>
        <VendedoresLoc showModal={showVendedoresLoc} onCloseModal={VendedoresLocClose}/>
    </>
}

export default Servicos;