import React from 'react';
import NumberFormat from "react-number-format";

function ProdutoCard(props) {
    let classeOferta=props.PRO_OFERTA==="N"?"text-success":"text-primary";
    return <>
        <div className="card rounded-0 border-0 border-bottom">
            <div className="card-body p-0">
                {/* <div className="row g-0"> */}
                <div className="d-flex justify-content-between">
                    <div className=""><img src={props.PRF_ARQUIVO} className="rounded img-fluid" alt="" style={{maxWidth: '60px'}}/></div>
                    <div className="w-100 m-0 p-2 text-truncate">
                        <div className="row text-truncate">
                            <div className="col-12 text-truncate fs-6 m-0 lh-1"><a className="link-preto stretched-link" href={'/produto/'+props.PRO_CODIGO}>{props.PRO_NOME}</a></div>
                            <div className="col-12 text-truncate text-secondary small mt-0 lh-1">{props.GRU_DESCRICAO}</div>                   
                            <div className="col-12 text-truncate text-secondary small d-flex justify-content-between lh-1">
                                <span className="">
                                    {props.PRO_CODIGO}    
                                </span>
                                <span className="">
                                    {props.PRO_UNIDADE}
                                </span>
                                <span className="">
                                    <NumberFormat 
                                        value={parseFloat(props.PRO_ESTOQUEATUAL)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix=""
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />                               
                                </span>
                                <span className={"fw-bold me-2 "+classeOferta}>
                                    <NumberFormat 
                                        value={parseFloat(props.PRO_PRECOVENDA)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix="R$ "
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
} 

export default ProdutoCard;