import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
//import '/node_modules/primeflex/primeflex.css';

// funcoes proprias
// import BarraInferior from '../componentes/BarraInferior';
import { AuthContext } from '../contextos/Auth';
import { ParametrosContext } from '../contextos/Parametros';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Clientes() {
    const { usuario, usuarioLogin, logout } = useContext(AuthContext);
    const { parametros } = useContext(ParametrosContext);
    const scrollObserver = useRef();
    const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState('');
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ cliente, setCliente ] = useState({
        CLI_BAIRRO: '',
        CLI_CEP: '',
        CLI_CIDADE: '',
        CLI_CNPJ: '',
        CLI_CODIGO: '',
        CLI_COMPLEMENTO: '',
        CLI_CONTATO: '',
        CLI_CPF: '',
        CLI_DTATUALIZACAO: '',
        CLI_DTNASC: '',
        CLI_EMAIL: '',
        CLI_ENDERECO: '',
        CLI_ESTADO: '',
        CLI_FONE1: '',
        CLI_FONE2: '',
        CLI_FONE3: '',
        CLI_IE: '',   
        CLI_NOME: '',
        CLI_NUMERO: '',
        CLI_RG: '',
        CLI_STATUS: 'A',
        CLI_TABELAPRECO: 'V',
        CLI_TIPOPESSOA: 'J',
        CLI_USUARIO: ''        
    });

    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ];

    const SelectItemsTIPOPESSOA = [
        {value: "F", label: "FISICA" },
        {value: "J", label: "JURIDICA" }
    ];

    const SelectItemsTABELAPRECO = [
        {value: "V", label: "VENDA" },
        {value: "1", label: "TABELA 1" },
        {value: "2", label: "TABELA 2" },
        {value: "3", label: "TABELA 3" },
        {value: "4", label: "TABELA 4" },
        {value: "5", label: "TABELA 5" },
        {value: "6", label: "TABELA 6" }
    ];
    
    function listarClientes(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                bodyFormData.set('pesquisa', pesquisa);
                       
            Api.post('clientes.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarClientes(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarClientes(0,false,true);
    };
    
    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','inserir');
        formData.set('CLI_BAIRRO',cliente.CLI_BAIRRO || '');
        formData.set('CLI_CEP',cliente.CLI_CEP || '');
        formData.set('CLI_CIDADE',cliente.CLI_CIDADE || '');
        formData.set('CLI_CNPJ',cliente.CLI_CNPJ || '');
        formData.set('CLI_CODIGO',cliente.CLI_CODIGO);
        formData.set('CLI_COMPLEMENTO',cliente.CLI_COMPLEMENTO || '');
        formData.set('CLI_CONTATO',cliente.CLI_CONTATO || '');
        formData.set('CLI_CPF',cliente.CLI_CPF || '');
        formData.set('CLI_DTNASC',cliente.CLI_DTNASC || '');
        formData.set('CLI_EMAIL',cliente.CLI_EMAIL || '');
        formData.set('CLI_ENDERECO',cliente.CLI_ENDERECO || '');
        formData.set('CLI_ESTADO',cliente.CLI_ESTADO || '');
        formData.set('CLI_FONE1',cliente.CLI_FONE1 || '');
        formData.set('CLI_FONE2',cliente.CLI_FONE2 || '');
        formData.set('CLI_FONE3',cliente.CLI_FONE3 || '');
        formData.set('CLI_IE',cliente.CLI_IE || '');
        formData.set('CLI_NOME',cliente.CLI_NOME || '');
        formData.set('CLI_NUMERO',cliente.CLI_NUMERO || '');
        formData.set('CLI_RG',cliente.CLI_RG || '');
        formData.set('CLI_STATUS',cliente.CLI_STATUS);
        formData.set('CLI_TABELAPRECO',cliente.CLI_TABELAPRECO);
        formData.set('CLI_TIPOPESSOA',cliente.CLI_TIPOPESSOA);
        formData.set('CLI_USUARIO',usuarioLogin);
        formData.set('CLI_VENDEDOR',usuario.vendedor);
        const CLI_USUARIO = usuarioLogin;
        const CLI_DTATUALIZACAO = moment().format('DD/MM/YYYY');
        setCliente({...cliente, CLI_DTATUALIZACAO, CLI_USUARIO});
        const response = await Api.post('clientes.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: 'Cliente cadastrado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setShowModal(false);
            navigate('/cliente/'+response.data.msg.CLI_CODIGO);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }    

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        if (name==='CLI_EMAIL') {
            valor = valor.toLowerCase();
        }
        setCliente({...cliente, [name]: valor});
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
      
    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CLIENTES</p></div>
                        <IfComponent condicional={parametros.PAR_CLIINSERIR==="S"}>
                            <div className="link-icone"><Link to='' onClick={() => setShowModal(true)} ><i className="fa fa-2x fa-fw fa-edit"></i></Link></div>
                        </IfComponent>
                    </div>
                </div>
            </div>
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <input className="form-control border-0 bg-light bg-opacity-10 rounded-pill text-light disable-form-control-focus" type="text" name="texto" value={pesquisa} placeholder="Localizar" onChange={(e) => setPesquisa(e.target.value.toUpperCase())} onKeyDown={(e) => localizarKeyDown(e)}/>
                        </div>
                        <div className="ms-2"><button className="btn btn-success rounded-pill" onClick={() => { Pesquisar(); }} >Filtrar</button></div>
                    </div>
                </div>
            </div>
        </div>
        <IfComponent condicional={ listagem.length > 0}>
            {/* <div className="container limit-width pb-2"></div> */}
            <div className="container limit-width pb-5 p-0 topspace-100">
                {                     
                    listagem.map(cliente => (
                        <div className="card rounded-0 border-0 border-bottom w-100" key={cliente.CLI_CODIGO}>
                            <div className="card-body p-0">
                                <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                    <Link to={"/cliente/"+cliente.CLI_CODIGO} className="link-preto stretched-link fw-bold">{cliente.CLI_NOME}</Link>
                                </div>
                                <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small">
                                    {cliente.CLI_FONE1} - {cliente.CLI_FONE2}
                                </div>
                            </div>
                        </div>
                    ))
                }   
            </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-user"></i></span><br/>Nenhum cliente encontrado!</p>
            </div>                    
        </IfComponent>
        {/* <BarraInferior active="clientes"/> */}

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">NOVO CLIENTE</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="row mb-0 mt-0 gx-1 p-inputtext-sm pett-input mt-2">
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CODIGO" value={cliente.CLI_CODIGO || ''} onChange={(e) => setCampo(e)} disabled readOnly/>
                                <label htmlFor="CLI_CODIGO">CÓDIGO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_STATUS" value={cliente.CLI_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_STATUS">STATUS</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_NOME" value={cliente.CLI_NOME || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_NOME">NOME / RAZÃO SOCIAL</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CONTATO" value={cliente.CLI_CONTATO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_CONTATO">CONTATO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_EMAIL" value={cliente.CLI_EMAIL || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_EMAIL">EMAIL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CNPJ" mask="99.999.999/9999-99" value={cliente.CLI_CNPJ || ''} onChange={(e) => setCampo(e)} ></InputMask>
                                <label htmlFor="CLI_CNPJ">CNPJ</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CPF" mask="999.999.999-99" value={cliente.CLI_CPF || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label className="" htmlFor="CLI_CPF">CPF</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_IE" value={cliente.CLI_IE || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_IE">INSCRIÇÃO ESTADUAL</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_RG" value={cliente.CLI_RG || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_RG">RG</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE1" mask="(999) 99999-9999" value={cliente.CLI_FONE1 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE1">FONE 01</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE2" mask="(999) 99999-9999"  value={cliente.CLI_FONE2 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE2">FONE 02</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_FONE3" mask="(999) 99999-9999"  value={cliente.CLI_FONE3 || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_FONE3">FONE 03</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_DTNASC" mask="99/99/9999"  value={cliente.CLI_DTNASC || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_DTNASC">DT. NASC.</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_ENDERECO" value={cliente.CLI_ENDERECO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_ENDERECO">ENDEREÇO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_NUMERO" value={cliente.CLI_NUMERO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_NUMERO">NÚMERO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputMask className="w-100" name="CLI_CEP" mask="99999-999" value={cliente.CLI_CEP || ''} onChange={(e) => setCampo(e)}></InputMask>
                                <label htmlFor="CLI_CEP">CEP</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_COMPLEMENTO" value={cliente.CLI_COMPLEMENTO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_COMPLEMENTO">COMPLEMENTO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_BAIRRO" value={cliente.CLI_BAIRRO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_BAIRRO">BAIRRO</label>
                            </span>
                        </div>
                        <div className="col-12 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_CIDADE" value={cliente.CLI_CIDADE || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_CIDADE">CIDADE</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="CLI_ESTADO" value={cliente.CLI_ESTADO || ''} onChange={(e) => setCampo(e)} />
                                <label htmlFor="CLI_ESTADO">ESTADO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-1">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_TABELAPRECO" value={cliente.CLI_TABELAPRECO || ''} options={SelectItemsTABELAPRECO} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_TABELAPRECO">TABELA DE PREÇO</label>
                            </span>
                        </div>
                        <div className="col-6 mb-4">
                            <span className="p-float-label pett-label">
                                <Dropdown className="w-100" name="CLI_TIPOPESSOA" value={cliente.CLI_TIPOPESSOA || ''} options={SelectItemsTIPOPESSOA} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                                <label htmlFor="CLI_TIPOPESSOA">TIPO PESSOA</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width btn-group p-0">
                    <Button className="w-100" variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                    <Button className="w-100" variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                </div>
            </Modal.Footer>
        </Modal>

    </>
}

export default Clientes;