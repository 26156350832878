import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';

//funcoes proprias.
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import ProdutoHeader from '../componentes/ProdutoHeader';
import IfComponent from '../componentes/IfComponent';

function ProdutoFotos() {   
    const { logout } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [ produto, setProduto ] = useState({});
    const [ imagens, setImagens ] = useState([]);

    const handleInputChange = (e,idx) => {
        const newValues=[];
        newValues.push(...imagens);
        if (e.target.name==='descricao') {
            newValues[idx].PRF_DESCRICAO=e.target.value;
        }
        if (e.target.name==='ordem') {
            newValues[idx].PRF_ORDEM=e.target.value;
        }
        setImagens(newValues);
        // const { name, value, type, checked } = e.target;
        // const isCheckBox = type === 'checkbox';
        // const data = imagens[name] || {};
        // if (isCheckBox) {
        //     data[value]=checked;
        // }
        // const newValue = isCheckBox ? data : value;
        // setImagens({...imagens, [name]: newValue});
    }

    function VerProduto() {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'verfotos');
        bodyFormData.set('produto', id);

        Api.post('produtos.php',bodyFormData)
        .then((response) => {
            setProduto(response.data.msg);
            if (response.data.erro==='N') {
                setProduto(response.data.msg);
                setImagens(response.data.msg.FOTOS);
            } else {
                setProduto({PRO_CODIGO:0});
                setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        });
    }  

    useEffect(() => {
        VerProduto();
        // eslint-disable-next-line
    },[]);

    function btnRotacionar(codigo, angulo, idx) {
        const formData = new FormData();
        formData.set('op','rotacionarfoto');
        formData.set('codigo',codigo);
        formData.set('angulo',angulo);

        Api.post('produtos.php',formData)
        .then((response) => {
            if (response.data.erro==='N') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Foto rotacionada com sucesso', //response.data.msg,
                    showConfirmButton: false,
                    timer: 1000
                }); 
                const newValues=[];
                newValues.push(...imagens);
                newValues[idx].PRF_ARQUIVO=response.data.msg;
                setImagens(newValues);
                //setDisco(response.data.msg);
                //setImagens(response.data.msg.FOTOS);
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                //setDisco({DIS_CODIGO:0});
                //setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        }); 
    }

    function btnSalvar(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.set('op','atualizarfoto');
        //const data = Object.fromEntries(formData);
        Api.post('produtos.php',formData)
        .then((response) => {
            console.log(response.data.msg);
            //setDisco(response.data.msg);
            if (response.data.erro==='N') {
                Swal.fire({ position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1000 }); 
                //setDisco(response.data.msg);
                //setImagens(response.data.msg.FOTOS);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                //setDisco({DIS_CODIGO:0});
                //setImagens([]);
            }
        })
        .catch((response) => {
            console.log('CATCH: '+JSON.stringify(response.data));
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});  
        });        
    }

    function btnExcluir(codigo) {
        const formData = new FormData();
        formData.set('op','excluirfoto');
        formData.set('foto',codigo);

        Swal.fire({
            title: 'Confirme!',
            text: "Quer mesmo eliminar a foto "+codigo+"?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, taca pau!',
            cancelButtonText: 'Nãããoooo...'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post('produtos.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({position: 'center', icon: 'success', title: response.data.msg, showConfirmButton: false, timer: 1500 });
                        const imagensNovo = imagens.filter((imagem) => imagem.PRF_CODIGO !== codigo);
                        setImagens(imagensNovo); 
                    }  else {
                        Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                        //setDisco({DIS_CODIGO:0});
                        //setImagens([]);
                    }
                }).catch((response) => {
                    Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
                })
            }
        })   
    }

    return <>
        {/* <BarraSuperior titulo="FOTOS DO PRODUTO" linkleft='voltar' linkright=''/> */}
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width p-0">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="">
                            <Link to="" onClick={() => navigate(-1)} className="link-icone"><i className="fa fa-2x fa-fw fa-chevron-left mx-2"></i></Link>
                        </div>
                        <div className="flex-grow-1 text-truncate"><p className="text-truncate fw-bold text-center text-light mt-3">FOTOS DO PRODUTO</p></div>
                        <div className="">
                            <div className="link-icone me-3">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={produto.PRO_CODIGO<=0}>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-record-vinyl"></i></span><br/>Não foi possível localizar o produto.</p>
            </div> 
        </IfComponent>
        <IfComponent condicional={produto.PRO_CODIGO>0}>
            <div className="container limit-width my-5 pt-2">
                <ProdutoHeader 
                    imagens={imagens}
                    PRF_ARQUIVO={produto.PRF_ARQUIVO}
                    PRO_CODIGO={produto.PRO_CODIGO}
                    PRO_ESTOQUEATUAL={produto.PRO_ESTOQUEATUAL}
                    PRO_NOME={produto.PRO_NOME}
                    PRO_PRECOVENDA={produto.PRO_PRECOVENDA}
                    PRO_UNIDADE={produto.PRO_UNIDADE}
                />
                    
                {imagens.map((imagem,idx) => {
                    return <div key={idx} className="card mb-3 bg-light">
                        <img src={imagem.PRF_ARQUIVO} className="card-img-top" alt={idx}/>
                        <div className="card-body m-0 ps-2 pe-2 pt-2 pb-0">
                            <form name={'form'+imagem.PRF_CODIGO} onSubmit={btnSalvar} method="POST">
                            <div className="row gx-1 p-inputtext-sm pett-input">
                                <div className="col-12 mb-1">
                                    <span className="p-float-label pett-label">
                                        <InputText className="w-100" name="descricao" value={imagem.PRF_DESCRICAO || ''} onChange={(e) => handleInputChange(e,idx)} />
                                        <label htmlFor="descricao">DESCRIÇÃO</label>
                                        <input type='hidden' name='codigo' value={imagem.PRF_CODIGO} />
                                        <input type='hidden' name='op' value='atualizar' />
                                    </span>
                                    {/* <input className="form-control" type="text" name="descricao" maxLength="150" value={imagem.PRF_DESCRICAO} onChange={(e) => handleInputChange(e,idx)} /><label className="control-label" htmlFor="descricao3677">Descrição</label> */}
                                </div>
                            </div>    
                            <div className="row gx-2 mb-2">
                                <div className="col-3 py-1">
                                    <button className="btn btn-info w-100" name="rotacionar90" type="button" onClick={() => btnRotacionar(imagem.PRF_CODIGO,90,idx)}>90&ordm;</button>
                                </div>      
                                <div className="col-3 py-1">
                                    <button className="btn btn-info w-100" name="rotacionar180" type="button" onClick={() => btnRotacionar(imagem.PRF_CODIGO,180,idx)}>180&ordm;</button>
                                </div>      
                                <div className="col-3 py-1">
                                    <button className="input-group-text btn btn-success w-100" name="salvar" type="submit">Salvar</button>
                                </div>      
                                <div className="col-3 py-1">
                                    <button className="btn btn-danger w-100" name="excluir" type="button" onClick={() => btnExcluir(imagem.PRF_CODIGO)}>Excluir</button>
                                </div>   
                            </div>    
                            </form>
                        </div>
                    </div>                    
                }) }
            </div>
        </IfComponent>
        {/* <BarraInferior active=""/> */}
    </>
}

export default ProdutoFotos;