import React from 'react';
//import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";

function VendasItensConferencia(props) {
    return <>
        <div className="card rounded-0 border-0 border-bottom">
            <div className="row g-0 mx-1 text-truncate">
                <div className="col-12 text-truncate text-black fs-6 m-0 lh-2">{props.VEI_DESCRICAO}</div>
            </div>
            <div className="row g-0 mx-2 mb-1">
                <div className="col-2 text-secondary small lh-2">{props.VEI_SEQUENCIA}</div>
                <div className="col-2 text-secondary small lh-2 text-end">{props.VEI_PRODUTO}</div>
                <div className="col-2 text-secondary small lh-2 text-end">{props.VEI_UNIDADE}</div>
                <div className="col-2 text-success small lh-2 text-end fw-bold">
                        <NumberFormat 
                            value={parseFloat(props.VEI_QUANTIDADE)}
                            // isNumericString={true}
                            displayType="text"
                            prefix=""
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={4}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                </div>
                <div className="col-2 text-primary small lh-2 text-end fw-bold">
                        <NumberFormat 
                            value={parseFloat(props.QTDECONFERIDA)}
                            // isNumericString={true}
                            displayType="text"
                            prefix=""
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={4}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                </div>
                <div className="col-2 text-secondary small lh-2 text-end" >
                        {
                            props.STATUSCONFERENCIA==='---'
                                ? <span className="badge bg-secondary">{props.STATUSCONFERENCIA}</span>
                                : <span className="badge bg-success">{props.STATUSCONFERENCIA}</span>
                        }                            
                </div>
                </div>
            </div>
        {/* </div> */}

        {/* <div className="card rounded-0 border-0 border-bottom">
            <div className="row g-0">
                <div className="col-12 m-0 p-2 d-flex align-items-center">
                    <div className="row text-truncate">
                        <div className="col-12 border text-truncate text-black fs-6 m-0 lh-2">{props.VEI_DESCRICAO}</div>
                        
                        <div className="col-12 border">
                            <div class="d-flex justify-content-between ">
                                <span className="text-secondary small lh-2">{props.VEI_SEQUENCIA} aaa</span>
                                <span className="text-secondary small lh-2">{props.VEI_PRODUTO}</span>
                                <span className="text-secondary small lh-2">{props.VEI_UNIDADE}</span>
                                <span className="text-success small lh-2 text-end fw-bold">
                                    <NumberFormat 
                                        value={parseFloat(props.VEI_QUANTIDADE)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix=""
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />
                                </span>
                                <span className="text-primary small lh-2 text-end fw-bold">
                                    <NumberFormat 
                                        value={parseFloat(props.QTDECONFERIDA)}
                                        // isNumericString={true}
                                        displayType="text"
                                        prefix=""
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator=","
                                        decimalScale={4}
                                        fixedDecimalScale={true}
                                        thousandSeparator="."
                                        allowNegative={true}
                                    />

                                </span>
                                <span className="text-secondary small lh-2" >
                                    {
                                        props.STATUSCONFERENCIA==='---'
                                            ? <span className="badge bg-secondary">{props.STATUSCONFERENCIA}</span>
                                            : <span className="badge bg-success">{props.STATUSCONFERENCIA}</span>
                                    }                            
                                </span>
                            </div>
                        </div> */}
                        
                        {/* <div className="col-12 border w-100 d-flex justify-content-between">
                        <div className="row border w-100 d-flex justify-content-between">

                            <div className="col-1 text-secondary small lh-2">
                                {props.VEI_SEQUENCIA}    
                            </div>
                            <div className="col-2 text-secondary small lh-2">
                                {props.VEI_PRODUTO}    
                            </div>
                            <div className="col-1 text-secondary small lh-2">
                                {props.VEI_UNIDADE}
                            </div>
                            <div className="col-2 text-end text-success small lh-2">
                                <NumberFormat 
                                    value={parseFloat(props.VEI_QUANTIDADE)}
                                    // isNumericString={true}
                                    displayType="text"
                                    prefix=""
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator=","
                                    decimalScale={4}
                                    fixedDecimalScale={true}
                                    thousandSeparator="."
                                    allowNegative={true}
                                />                               
                            </div>
                            <div className="col-3 text-end text-primary small lh-2 fw-bold">
                                <NumberFormat 
                                    value={parseFloat(props.QTDECONFERIDA)}
                                    // isNumericString={true}
                                    displayType="text"
                                    prefix=""
                                    thousandsGroupStyle="thousand"
                                    decimalSeparator=","
                                    decimalScale={4}
                                    fixedDecimalScale={true}
                                    thousandSeparator="."
                                    allowNegative={true}
                                />
                            </div>
                            <div className="col-3 text-end text-success small lh-2 fw-bold">
                                {
                                    props.STATUSCONFERENCIA==='---'
                                        ? <span className="badge bg-secondary">{props.STATUSCONFERENCIA}</span>
                                        : <span className="badge bg-success">{props.STATUSCONFERENCIA}</span>
                                }                            
                            </div>

                        </div>
                        </div> */}
                    {/* </div>
                </div>
            </div>
        </div> */}
    </>
} 

export default VendasItensConferencia;