import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';

function Home () {

    const { logado, logout, usuarioNome, empresa, versaoBase } = useContext(AuthContext);
    
    return <>
        <IfComponent condicional={logado===true}>
            <div className="bg-dark" style={{height: '100vh'}}>
            <div className="container-fluid limit-width pt-5 text-center">
                <img src='/images/logo_runtime_black.svg' style={{maxWidth: '280px'}} className="mb-2" alt='Brian' />
                <div className="col-12 mb-2 text-white text-opacity-50">
                    <span className="fw-bold">{empresa}</span><br/>{usuarioNome}
                     {/* {process.env.REACT_APP_URL_API} */}
                </div>
                <ul className="list-group mb-2 text-start">
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/clientes' className="link-preto stretched-link w-100"><img className="icone25 me-3" src="/images/user_group.svg" alt="" /> Clientes</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/produtos' className="link-preto stretched-link w-100"><img className="icone25 me-3" src="/images/brick.svg" alt="" /> Produtos</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/receber' className='link-preto stretched-link w-100'><img className="icone25 me-3" src="/images/paper_money.svg" alt="" /> Contas a Receber</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/vendas' className='link-preto stretched-link w-100'><img className="icone25 me-3" src="/images/shopping_cart.svg" alt="" /> Pedido de Vendas</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/vendasconferencias' className='link-preto stretched-link w-100'><img className="icone25 me-3" src="/images/barcode_scanner.svg" alt="" /> Conferência de Pedido de Vendas</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/servicos' className='link-preto stretched-link w-100'><img className="icone25 me-3" src="/images/drill.svg" alt="" /> Ordens de Serviço</Link></li>
                    <li className="list-group-item d-flex justify-content-between align-items-center"><Link to='/metas' className='link-preto stretched-link w-100'><img className="icone25 me-3" src="/images/increase.svg" alt="" /> Minhas Metas</Link></li>
                </ul>
                <div className="col-12 mb-5 text-white text-opacity-50">
                    {'Versão Base: ' + versaoBase + ' - Versão App: '+process.env.REACT_APP_APP_VER}
                </div>
                <div className="col-12">
                    <button className="btn btn-lg btn-danger w-100" onClick={logout}><i className="pi pi-fw pi-sign-out"></i> DESCONECTAR</button>
                </div>
            </div>
            </div>
        </IfComponent>
    </>
}

export default Home;